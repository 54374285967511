<template>
    <div class="container-fluid">
        <div class="row mt-4">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col ml-n2">
                                <h4 class="mb-1">
                                    <a>{{$t('purchaseCheques.show.purchasePayment')}} <strong> {{item.code}}</strong></a>
                                </h4>
                            </div>
                            <div class="col-auto">
                                <router-link :to="path+'/'+item.id + '/edit'" class="btn btn-sm btn-primary" :title="$t('purchaseCheques.show.Modification')">
                                    <i class="fas fa-edit"></i>
                                </router-link>
                                <a @click="print(item.id)" class="btn btn-sm btn-primary" :title="$t('purchaseCheques.show.Printing')">
                                    <i class="fas fa-print"></i>
                                </a>
                                <a :href="$linkGnirator('/purchaseCheques/' + item.id + '/pdf')" download class="btn btn-sm btn-primary" :title="$t('purchaseCheques.show.DownloadPDF')">
                                    <i class="fas fa-download"></i>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-header-title">
                            <i class="fal fa-credit-card-front"></i>
                            {{$t('purchaseCheques.show.PaymentDetails')}}
                        </h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-9">
                                <table class="table table-sm table-nowrap customed-table-small">
                                    <tbody>
                                        <tr>
                                            <th scope="row">{{$t('purchaseCheques.form.PaymentNumber')}} </th>
                                            <td>{{item.code}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('purchaseCheques.show.RegistrationDate')}}</th>
                                            <td>{{$dateFormat(item.created_date)}} </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('purchaseCheques.form.PaymentDate')}}</th>
                                            <td>{{$dateFormat(item.date)}} </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">{{$t('purchaseCheques.show.Administrator')}}</th>
                                          <td>{{item.user ? item.user.name : '--'}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('purchaseCheques.show.referenceNumber')}}</th>
                                            <td>{{item.refrance != null? item.refrance : '--'}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('purchaseCheques.show.ReceiveFrom')}}</th>
                                            <td>
                                              <router-link :to="'/customers/' + item.customer.id" v-if="item.client_type == 0">{{item.customer.name}}</router-link>
                                              <router-link :to="'/suppliers/' + item.supplier.id" v-if="item.client_type == 1">{{item.supplier.name}}</router-link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('purchaseCheques.form.PaymentMethod')}}</th>
                                            <td scope="row">{{item.paymentMethod != null ? item.paymentMethod.name : '--'}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('purchaseCheques.show.Treasury')}}</th>
                                            <td>
                                                <router-link :to="'/safes/' + item.safe.id" v-if="item.safe">{{ item.safe.name}}</router-link>
                                                    {{item.safe ? '' : '--'}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-xl-3">
                                <div class="price-show">
                                    <div class="price-content">
                                        <p>{{$t('purchaseCheques.form.cash')}}</p>
                                        <h1>{{$moneyFormat(item.cost)}} </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h4 class="card-header-title">
                            <i class="fal fa-user-alt"></i>
                            {{$t('purchaseCheques.show.ReceiveFrom')}}
                        </h4>
                    </div>
                    <div class="data-table-header card-body">
                        <div class="row">
                            <div class="col-xl-2">
                                <div>
                                    <div class="row">
                                        <div class="col-xl-12 col-6">{{$t('purchaseCheques.show.customerName')}}</div>
                                        <div class="col-xl-12 col-6">
                                            <router-link :to="'/customers/' + item.customer.id"  v-if="item.client_type == 0">{{item.customer.name}}</router-link>
                                            <router-link :to="'/suppliers/' + item.supplier.id"  v-if="item.client_type == 1">{{item.supplier.name}}</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2">
                                <div class="row">
                                    <div class="col-xl-12 col-6">{{$t('purchaseCheques.form.cash')}}</div>
                                    <div class="col-xl-12 col-6"> {{$moneyFormat(item.cost)}} </div>
                                </div>
                            </div>
                            <div class="col-xl-2">
                                <div class="row">
                                    <div class="col-xl-12 col-6">{{$t('purchaseCheques.form.BankFees')}}</div>
                                    <div class="col-xl-12 col-6"> {{$moneyFormat(item.bank_fees)}} </div>
                                </div>
                            </div>
                            <div class="col-xl-2">
                                <div class="row">
                                    <div class="col-xl-12 col-6">{{$t('purchaseCheques.form.BankTax')}}</div>
                                    <div class="col-xl-12 col-6"> {{$moneyFormat(item.bank_tax_amount)}} </div>
                                </div>
                            </div>
                            <div class="col-xl-2">
                                <div class="row">
                                    <div class="col-xl-12 col-6">{{$t('purchaseCheques.show.PreviousBalance')}}</div>
                                    <div class="col-xl-12 col-6"> {{$moneyFormat(item.old_balance)}} </div>
                                </div>
                            </div>
                            <div class="col-xl-2">
                                <div class="row">
                                    <div class="col-xl-12 col-6">{{$t('purchaseCheques.show.TotalBalance')}}</div>
                                    <div class="col-xl-12 col-6"> {{$moneyFormat(item.new_balance)}} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <showFile/>
            </div>
        </div>
        <print v-if="printInvoice" :options="$option"></print>
    </div>
</template>
<script>
    import axios from 'axios';
    import showFile from '../elements/show/showFile.vue';
    import print   from '../../print/purchasePayment.vue';
    export default {
        data() {
            return {
                path: '/purchaseCheques',
                item: {},

                printInvoice: false,
            }
        },
        mounted() {
            this.getitem();
        },
        methods: {
            getitem() {
                axios.get(this.$linkGnirator(this.path + '/' + this.$route.params.id))
                    .then(response => {
                        this.item = response.data;
                    });
            },
            print (id) {
                this.printInvoice = id;
            }
        },
        components: {
            print,
            showFile,
        }
    };

</script>
<style>
</style>
